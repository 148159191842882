import React from 'react';
import hdijab from './images/hdijab.png';
import './Main.css';
import Text from './Text';

class Navigation extends React.Component {

  renderListItem(title) {
    const ref = "#" + title.replace(/\s+/g, '-').toLowerCase()
    return (
      <li className="nav-item">
        <a className="nav-link js-scroll-trigger" href={ref}>{title}</a>
      </li>
    )
  }

  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
        <div className="container">
          <a className="navbar-brand js-scroll-trigger" href="/">
            <img className="hdijab" src={hdijab} alt={"HDijab"}/>
          </a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ml-auto">
              {this.renderListItem("Work")}
              {this.renderListItem("Education")}
              {this.renderListItem("Skills")}
              {this.renderListItem("Projects")}
            </ul>
          </div>
        </div>
      </nav>
    )
  }
}

class Main extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sections: ["Work", "Education", "Skills", "Projects"]
    }
  }

  renderHeader() {
    return (
      <header className="text-white">
        <div className="container text-left">
          <h1>Curriculum Vitae</h1>
          <p className="lead">
            Email: <a className="mailto" href="mailto:contact@halildijab.com">
            contact@halildijab.com
            </a>
          </p>
        </div>
      </header>
    )
  }

  renderSection(title, ifLight) {
    const id = title.replace(/\s+/g, '-').toLowerCase()
    const checkLight = ifLight ? "bg-light" : ""
    return (
      <section id={id} className={checkLight} key={id}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mx-auto">
              <h2><u>{title}</u></h2>
              {Text(title)}
            </div>
          </div>
        </div>
      </section>
    )
  }

  renderFooter() {
    return (
      <footer className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-ms-6 mx-auto">
              <a className="mailto" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/hdijab/">LinkedIn</a>
            </div>
            <div className="col-ms-6 mx-auto">
              <a className="mailto" target="_blank" rel="noopener noreferrer" href="https://github.com/HDijab">GitHub</a>
            </div>
          </div>
        </div>
      </footer>
    )
  }

  render() {
    return (
      <div className="Main">
        <Navigation />
        {this.renderHeader()}
        {this.state.sections.map((v,i) => {return this.renderSection(v, i % 2 === 0)})}
        {this.renderFooter()}
      </div>
    )
  }
}

export default Main;
