import React from 'react';

export default function Text(section) {
  if (section === "Work") {
    return (
      <div className="container">
        <div className="subsection">
          <h3> June 2018 – August 2018 | Research Intern | King’s College London </h3>
          <p className="lead">
            Aided in the data analytic research of a project in the department of biomedical engineering, my main supervisor was Dr. Peter Charlton
          </p>
          <p>
            The research was submitted to a conference with the following citation:</p><p>
            Dijab, Halil & Alastruey, Jordi & Charlton, Peter. (2018). Measuring Vascular Recovery Rate After Exercise. Proceedings. 4. 5746. 10.3390/ecsa-5-05746.
          </p>
        </div>
        <div className="subsection">
          <h3> October 2017 – March 2018 | Student Proctor | University of Hertfordshire </h3>
          <p className="lead">
            I provided aid to professors during tutorial sessions and helped invigilate examinations while tutoring students struggling with the lecture material
          </p>
        </div>
      </div>
    )
  } else if (section === "Education") {
    return (
      <div className="container">
        <div className="subsection">
          <h3> 2016 - 2019: Computer Science with A.I. BSc (Hons) | University of Hertfordshire </h3>
          <p className="lead">Expected final grade: First Class (82%)</p>
          <p className="lead">Final year | Average grade: First Class (79%)</p>
          <p className="lead">Second year | Average grade: First Class (74%)</p>
          <p className="lead">First year | Average grade: First Class (88%)</p>
        </div>
        <div className="subsection">
          <h3> January 2016 – March 2016: Web Development Bootcamp | Ironhack, Spain </h3>
          <p className="lead">
            Equivalent to 400 teaching hours
          </p>
          <p>Learned to use:</p>
          <ul>
            <li>Ruby on Rails</li>
            <li>HTML5</li>
            <li>CSS/Sass</li>
            <li>JavaScript/JQuery</li>
          </ul>
        </div>
        <div className="subsection">
          <h3> 2007 – 2013: Secondary School | Qatar Academy, Qatar </h3>
          <p className="lead"> International Baccalaureate </p>
        </div>
      </div>
    )
  } else if (section === "Skills") {
    return (
      <div className="container">
        <div className="subsection">
          <p className="lead">Natural Languages:</p>
          <ul>
            <li>English</li>
            <li>Arabic</li>
            <li>Serbian</li>
          </ul>
          <p className="lead">Programming Languages:</p>
          <ul>
            <li>Ruby</li>
            <li>Python</li>
            <li>Java</li>
            <li>MATLAB</li>
            <li>Swift</li>
            <li>JavaScript</li>
            <li>C/C++</li>
            <li>Haskell</li>
          </ul>
          <p className="lead">
            Developed leadership, initiative and organizational skills as the Computer Science school officer in the second year of my university degree, by organizing student representatives to bridge the gap between students and academic staff
          </p>
          <p className="lead">
            Developed communication, independence and teaching skills as a peer assisted learning tutor also during my second year, by teaching first year students programming and assisting them in their studies
          </p>
        </div>
      </div>
    )
  } else if (section === "Projects") {
    return (
      <div className="container">
        <div className="subsection">
          <h3> January 2019 – April 2019 | Final Year Project (Grade: 82%) </h3>
          <p className="lead">
            New approach to one shot learning for facial recognition
          </p>
          <p>
            Exploring the use of autoencoders trained on human faces to generate a dataset from a single image of a person for a convolutional neural network to learn to recognize said person
          </p>
        </div>
        <div className="subsection">
          <h3> October 2017 | AI Module Coursework (Grade: 100%) </h3>
          <p className="lead">
            Programming an autonomous vehicle
          </p>
          <p>
            I programmed a car in NetLogo that was driven by a neural network, which was trained by the means of genetic algorithms to circumnavigate a track with obstacles and oncoming traffic
          </p>
        </div>
        <div className="subsection">
          <h3> March 2017 – April 2017 | Hardware Module Coursework (Grade: 100%) </h3>
          <p className="lead">
            Building an 8-bit game
          </p>
          <p>
            Used Logisim to design a chess game by programming a simple 8-bit processor provided by my professor and augment it with further hardware for graphics and logic control
          </p>
        </div>
      </div>
    )
  }
}